import https from "./https";
import store from "@/store";

const orgCode = store.state.liffGeneral.orgCode;

const news = {
  async getList(params) {
    const { data } = await https.get(`${orgCode}/liff/posts`, { params })
    return data
  },

  async get(id) {
    const { data } = await https.get(`${orgCode}/liff/posts/${id}`)
    return data
  },
};

export default news
