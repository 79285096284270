<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <template v-if="newsList.length">
        <ul class="s-pb-3">
          <li class="s-p-3 pb-0" v-for="(news, i) in newsList" :key="i">
            <Item
              :news="news"
            />
          </li>
        </ul>
        <Pagination :current="page" :last="lastPage" />
      </template>
      <div class="s-pt-40 s-text-center" v-else>
        <img class="s-mb-3" src="@/assets/images/liff/empty.svg" />
        <p class="s-text-gray">沒有最新消息</p>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '@/components/Page/Liff/Shared/Loading'
import Item from "@/components/Page/Liff/News/Item";
import Pagination from "@/components/Page/Liff/OrderList/Pagination";
import newsApi from "@/apis/liff/v2/news";

export default {
  components: {
    Loading,
    Item,
    Pagination,
  },
  data: () => ({
    fetching: true,
    newsList: [],
    lastPage: 1,
  }),
  computed: {
    page() {
      return +this.$route.query.page || 1;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    page: "init",
  },
  methods: {
    init() {
      this.fetch();
    },
    async fetch() {
      this.newsList = [];
      this.lastPage = 1;
      this.fetching = true;

      try {
        const { data, meta: {last_page: lastPage} } = await newsApi.getList({
          is_active: 1,
          page: this.page,
        });

        this.newsList = data;
        this.lastPage = lastPage;
      } catch (e) {
        console.log(e);
      }

      this.fetching = false;
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  border: 1px solid var(--s-gray-dark);
  border-radius: 0.5rem;
  padding: 0 1rem;
}
.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
    &:read-only {
      background-color: #f0f0f0;
    }
  }
}
</style>
